import { styled } from '@mui/material/styles';
import React, { useCallback, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Link, Menu, Tooltip, Zoom } from '@mui/material';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


// const LightTooltip = styled(({ className, ...props }) => (<Tooltip {...props} classes={{ popper: className }} />))(({ theme }) => null);


const NavbarMenuLinkMobile = ({ item, onClick = () => null }) => {

  const url = window.location.pathname
  const [menuDropdown, setMenuDropdown] = useState(null);
  // const [isTooltip, setIsTooltip] = useState(false);


  // const renderMenu = useCallback(() => {

  //   return (
  //     <Box>

  //       {
  //         item?.items?.map((subItem, index) => {

  //           let isSubActive = false
  //           if (subItem.link === url) {
  //             isSubActive = true;
  //           }
  //           return (
  //             <Link
  //               key={subItem?.id ?? index}
  //               to={subItem?.link}
  //               component={RouterLink}
  //               onClick={onClick}
  //               sx={{
  //                 ...styles?.itemStyle,
  //                 fontSize: '14px',
  //                 borderRadius: '0px',
  //                 ...(isSubActive && {
  //                   backgroundColor: 'rgba(28, 28, 28, 0.1)',
  //                   color: '#000',
  //                 }),
  //               }}
  //             >
  //               {subItem?.text}
  //             </Link>
  //           )
  //         })
  //       }

  //     </Box>
  //   )
  // })

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  // ################  make active link function ###############
  const findIsActive = () => {
    if (url === item?.link) {
      console.log(url, item?.link)
      return true;
    }

    if (item?.link === "/project") {
      if (url === "/objectives" || url === "/workpackages") {
        return true;
      }
    }

    if (item?.link === "/participants") {
      if (url === "/candidates" || url === "/partners" || url === "/managementTeam") {
        return true;
      }
    }

    return false
  }
  const isActive = findIsActive()



  // ################  styles ###############
  const styles = {
    itemStyle: {
      fontSize: '15px',
      fontFamily: 'Poppins-Medium',
      color: '#000',
      textDecoration: 'none',
      textTransform: 'uppercase',
      px: '10px',
      py: '10px',
      cursor: 'pointer',
      borderRadius: '8px',

      display: 'flex',
      // alignItems: 'center',
      width: { xs: '100%', lg: 'auto' },

      '&:hover': {
        backgroundColor: 'rgba(28, 28, 28, 0.1)',
        color: '#000',
      }
    }
  }





  // ################  dropdown link item ###############
  if (item?.items?.length > 0) {

    return (
      <Box sx={{ width: { xs: '100%', lg: 'auto', } }}>



        <Button

          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            ...styles?.itemStyle,
            ...(isActive && {
              backgroundColor: 'rgba(28, 28, 28, 0.1)',
              color: '#000',
            }),
            pr: '0px',
            justifyContent: 'space-between',
            flexGrow: 1,
            width: { xs: '100%', lg: 'auto' },
            width: '100%',
          }}
        // onClick={(event) => setMenuDropdown(event.currentTarget)}
        // onMouseOver={(event) => setMenuDropdown(event.currentTarget)}
        // onMouseLeave={(event) => setMenuDropdown(null)}
        >
          {item?.text}
          {menuDropdown ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
        </Button>


        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{

            '& > .MuiMenu-paper > *': {
              p: '0px !important',
              borderRadius: '8px',
              // backgroundColor: 'red',
            },
            '& > .MuiMenu-paper ': {
              // p: '0px !important',
              borderRadius: '8px',
              // backgroundColor: 'red',
            },
          }}
        >
          {
            item?.items?.map((item) => {

              let isSubActive = false
              if (item.link === url) {
                isSubActive = true;
              }

              return (
                <Link
                  key={item.text}
                  to={item?.link}
                  component={RouterLink}
                  onClick={onClick}
                  sx={{
                    // ...styles?.itemStyle,
                    // ...(isActive && {
                    //   backgroundColor: 'rgba(28, 28, 28, 0.1)',
                    //   color: '#000',
                    // }),
                    ...styles?.itemStyle,
                    fontSize: '14px',
                    borderRadius: '0px',
                    ...(isSubActive && {
                      backgroundColor: 'rgba(28, 28, 28, 0.1)',
                      color: '#000',
                    }),
                  }}
                >
                  {item?.text}
                </Link>
              )
            })
          }
        </Menu>

      </Box>
    )
  }

  // ################  normal link item ###############
  return (
    <Link
      to={item?.link}
      component={RouterLink}
      onClick={onClick}
      sx={{
        ...styles?.itemStyle,
        ...(isActive && {
          backgroundColor: 'rgba(28, 28, 28, 0.1)',
          color: '#000',
        }),
      }}
    >
      {item?.text}
    </Link>
  )

}

export default NavbarMenuLinkMobile