import React from 'react'
import { AppLayout } from 'layouts'
import { Box, Link, Typography } from '@mui/material'


const disseminationList = [
    {
        text: 'Public Deliverables',
        link: '#',
    },
    {
        text: 'Journal Papers',
        link: '#',
    },
    {
        text: 'Conference Papers',
        link: '#',
    },
]


const Dissemination = () => {
    return (
        <AppLayout>
            <Typography
                sx={{
                    fontFamily: 'Poppins-Semibold',
                    fontSize: '20px',
                    lineHeight: '24px',
                    color: '#222',
                    mb: '20px',
                    textTransform: 'uppercase',
                    textAlign: { xs: 'center', md: 'left' }
                }}
            >
                Dissemination
            </Typography>

            <Typography
                sx={{
                    fontFamily: 'Poppins-Regular',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#666',
                    mb: '20px',
                    // textAlign: { xs: 'center', md: 'left' }
                }}
            >
                You can examine and download public documents and results of SCION as above:
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                }}
            >
                {
                    disseminationList?.map((item, index) => {

                        return (
                            <Link
                                key={index}
                                href={item.link}
                                sx={{
                                    fontFamily: 'Poppins-Medium',
                                    fontSize: '16px',
                                    lineHeight: '20px',
                                    color: '#000',
                                }}
                            >
                                {item?.text}
                            </Link>
                        )
                    })
                }
            </Box>


        </AppLayout>
    )
}

export default Dissemination