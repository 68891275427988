import React from 'react'
import { Box, Link, Typography } from '@mui/material'


const PartnerCardOne = ({ item }) => {
    return (
        <Link
            href={item.companyWebLink}
            target='_blank'
            sx={{
                // width: { xs: '100%', sm: '200px' },
                maxWidth: { xs: '300px', sm: '200px' },
                m: { xs: 'auto', sm: '0' },
                flex: { xs: '0 0 100%', sm: '0 0 200px' },
                // border: '1px solid #ccc',
                boxShadow: '1px 1px 15px 0px #ccc',
                p: '5px',
                textDecoration: 'none',

            }}
        >
            <Box
                sx={{
                    height: { xs: '100%', sm: '200px' },
                    flex: { xs: '0 0 100%', sm: '0 0 200px' },
                    m: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pb: '5px',
                    borderBottom: '1px solid #ccc',
                }}
            >
                <img
                    src={item?.imgUrl}
                    alt={item.name}
                    style={{
                        width: '90%',
                        height: '90%',
                        objectFit: 'contain',

                    }}
                />
            </Box>
            <Typography
                sx={{
                    fontFamily: 'Poppins-Regular',
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: '#222',
                    textAlign: 'center',
                    my: '20px',
                }}
            >
                {item?.name}
            </Typography>
        </Link>
    )
}

export default PartnerCardOne