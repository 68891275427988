import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

import GoogleMapReact from 'google-map-react';
import { AppLayout } from 'layouts'


const AnyReactComponent = ({ text }) => {
    return (
        <Box
            sx={{
                width: '40px',
                height: '40px',
                backgroundColor: '#3038A8',
                // borderRadius: '50%',

                transform: 'rotate(45deg)',
                borderRadius: '50%',
                borderEndEndRadius: '0px',

            }}
        >
            <Box
                sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#3038A8',
                    // position: 'absolute',
                    // position: 'relative',
                    // top: '-2px',
                    // left: '-2px',
                    transform: 'rotate(-45deg)',
                    color: '#FFFFFF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',

                }}
            >
                {text}
            </Box>
        </Box>
    )
};

const defaultProps = {
    center: {
        lat: 38.3226588,
        lng: 26.637729502462555
    },
    zoom: 12
};



const Contact = () => {
    return (
        <AppLayout>
            <Typography
                sx={{
                    fontFamily: 'Poppins-Semibold',
                    fontSize: '20px',
                    lineHeight: '24px',
                    color: '#222',
                    mb: '20px',
                    textTransform: 'uppercase',
                    textAlign: { xs: 'center', md: 'left' }
                }}
            >
                Contact
            </Typography>

            <Grid container spacing={3}>


                <Grid item xs={12} md={12}>
                    <Box>
                        <Typography
                            style={{
                                fontFamily: 'Poppins-Regular',
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: '#666',
                                textAlign: 'justify',
                            }}
                        >
                            <span
                                style={{
                                    fontFamily: 'Poppins-Medium',
                                    fontSize: '18px',
                                    lineHeight: '22px',
                                    color: '#000',
                                }}
                            >
                                IYTE Legal address :
                            </span> &nbsp;
                            Izmir Institute of Technology, Gulbahce, Urla,Izmir,35430, Türkiye
                        </Typography>

                        <Typography
                            style={{
                                fontFamily: 'Poppins-Regular',
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: '#666',
                                textAlign: 'justify',
                            }}
                        >
                            <span
                                style={{
                                    fontFamily: 'Poppins-Medium',
                                    fontSize: '18px',
                                    lineHeight: '22px',
                                    color: '#000',
                                }}
                            >
                                Coordinator :
                            </span> &nbsp;
                            bernaozbek@iyte.edu.tr
                        </Typography>

                        <Typography
                            style={{
                                fontFamily: 'Poppins-Regular',
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: '#666',
                                textAlign: 'justify',
                            }}
                        >
                            <span
                                style={{
                                    fontFamily: 'Poppins-Medium',
                                    fontSize: '18px',
                                    lineHeight: '22px',
                                    color: '#000',
                                }}
                            >
                                General enquiries :
                            </span> &nbsp;
                            scion@iyte.edu.tr
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Box
                        sx={{
                            height: { xs: '300px', sm: '400px', md: '400px', lg: '400px', xl: '400px' },
                            width: '100%',
                        }}
                    >
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "" }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                        >
                            <AnyReactComponent
                                lat={defaultProps.center.lat}
                                lng={defaultProps.center.lng}
                                text="IYTE"
                            />
                        </GoogleMapReact>
                    </Box>
                </Grid>

            </Grid>

        </AppLayout>
    )
}

export default Contact