export const routerLinks = {
    home: "/home",

    workpackages: "/workpackages",
    objectives: "/objectives",

    candidates: "/candidates",
    personDetail: "/person-detail/:name",
    partners: "/partners",
    managementTeam: "/managementTeam",

    training: "/training",
    news: "/news",
    newsDetail: "/news-detail/:name",
    dissemination: "/dissemination",
    contact: "/contact",

    cookies: "/cookies",
    // participants: "/participants",
    // project: "/project",
}

export const headerMenu = [
    {
        id: "1",
        text: 'Home',
        link: '/',
        items: []
    },
    {
        id: "2",
        text: 'Project',
        link: '/project',
        items: [
            {
                id: "1",
                text: 'Objectives',
                link: '/objectives',
            },
            {
                id: "2",
                text: 'Workpackages',
                link: '/workpackages',
            },
        ]
    },
    {
        id: "3",
        text: 'Participants ',
        link: '/participants',
        items: [
            {
                id: "1",
                text: 'Doctoral Candidates',
                link: '/candidates',
            },
            {
                id: "2",
                text: 'Partners',
                link: '/partners',
            },
            {
                id: "3",
                text: 'Management Team',
                link: '/managementTeam',
            },
        ]
    },
    {
        id: "4",
        text: 'Training',
        link: '/training',
        items: []
    },
    {
        id: "5",
        text: 'News',
        link: '/news',
        items: []
    },
    {
        id: "6",
        text: 'Dissemination',
        link: '/dissemination',
        items: []
    },
    {
        id: "7",
        text: 'Contact',
        link: '/contact',
        items: []
    },
]

export const footerMenuList = {
    col1: {
        title: "The SCION Project",
        items: [
            {
                id: "1",
                text: 'Home',
                link: '/',
            },
            {
                id: "2",
                text: 'Training',
                link: '/training',
            },
            {
                id: "3",
                text: 'News',
                link: '/news',
            },
            {
                id: "4",
                text: 'Dissemination',
                link: '/dissemination',
            },
            {
                id: "5",
                text: 'Contact',
                link: '/contact',
            },
        ]
    },
    col2: {
        title: "Resources",
        items: [
            {
                id: "1",
                text: 'Objectives',
                link: '/objectives',
            },
            {
                id: "2",
                text: 'Workpackages',
                link: '/workpackages',
            },
            {
                id: "3",
                text: 'Doctoral Candidates',
                link: '/candidates',
            },
            {
                id: "4",
                text: 'Partners',
                link: '/partners',
            },
            {
                id: "5",
                text: 'Management Team',
                link: '/managementTeam',
            },
            {
                id: "6",
                text: 'Cookies',
                link: '/cookies',
            },
        ]
    },
}