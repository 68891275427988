import { Box, Typography } from '@mui/material'
import { EuropeFlagImage2 } from 'assets'
import { LogoComponentOne } from 'components'
import React from 'react'

const HomeRight = () => {
    return (
        <Box>

            <Typography
                sx={{
                    fontFamily: 'Poppins-Semibold',
                    fontSize: '18px',
                    lineHeight: '22px',
                    color: '#222'
                }}
            >
                Project summary
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    // alignItems: 'center',
                    gap: { xs: '20px' },
                    mt: { xs: '10px' },
                    mt: { xs: '20px' },
                }}
            >
                {/* <Box sx={{ width: '100px', height: '100px', flex: '0 0 100px' }}>
                    <img src="https://picsum.photos/300/300" alt="" style={{ width: '100%', height: '100%' }} />
                </Box> */}
                {/* <LogoComponentOne /> */}
                <Box
                    sx={{
                        // height: { xs: 'auto', sm: '80px' },
                        width: { xs: '100%', sm: 'auto' }
                    }}
                >
                    <img src={EuropeFlagImage2} alt="" style={{ width: '100%' }} />
                </Box>

                <Typography
                    sx={{
                        fontFamily: 'Poppins-MediumItalic',
                        fontSize: '14px',
                        lineHeight: '18px',
                        color: '#444',
                        textAlign: 'justify',
                    }}
                >
                    To satisfy the expected plethora of demanding services,
                    6G is envisioned as a revolutionary paradigm to carry
                    forward the capacities of enhanced broadband, massive
                    access, and Ultra-reliable and low latency services in
                    5G wireless networks to a more robust and intelligent level.
                </Typography>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: '20px' },
                    mt: { xs: '10px' },
                    mt: { xs: '20px' },

                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Poppins-Regular',
                        fontSize: '14px',
                        lineHeight: '18px',
                        color: '#666',
                        textAlign: 'justify',
                    }}
                >
                    . This move will introduce significant new multidisciplinary research challenges emerging throughout the wireless communication protocol stacks, including the way the mobile network is modelled and deployed. The structure of 6G networks will be highly heterogeneous, densely deployed, and dynamic. Combined with the tight quality of services, such complex architecture will result in the untenability of legacy network operation routines. In response, artificial intelligence (AI), especially machine learning (ML), Semantic Communication and Digital Twin (DT) are emerging as solutions to realize the fully intelligent network orchestration and management. By learning from uncertain and dynamic environments, AI-enabled channel estimation and spectrum management will open up opportunities for bringing the excellent performance of Ultra-broadband techniques into full play. Additionally, challenges brought by Ultra-massive access concerning energy and security can be mitigated by applying AI-based approaches.
                    <br /><br />
                    The overall research objective of the SCION project is to develop and design the network systems for seamless wireless access for Secure and Intelligent Massive Machine-to-Machine Communications for 6G. In addition to technology development, to meet the urgent needs for the future working force of the coming 6G era, this collaborative project will create a training network for Doctoral Candidates who will contribute to the design and implementation of future 6G networks.


                </Typography>

                {/* <ul
                    style={{
                        fontFamily: 'Poppins-Regular',
                        fontSize: '14px',
                        lineHeight: '18px',
                        color: '#666',
                        textAlign: 'justify',
                        marginLeft: '20px',
                    }}
                >
                    <li>
                        Objective 1 – Developing RISs. Bring to light the third generation of meta-materials technology by developing RISs that can be reconfigured in real-time and are able to perform joint communication and sensing tasks.
                    </li>

                    <li>
                        Objective 2 – Theoretical frameworks. Develop new mathematical techniques to introduce a novel communication theory that overcomes conventional Shannon’s theory and unveils the ultimate performance of RIS-based networks.
                    </li>

                    <li>
                        Objective 3 – Algorithmic frameworks. Develop new communication schemes, optimization protocols, and algorithms for RIS-based networks, coping with the large degrees of freedom and the passive nature of RISs.
                    </li>

                    <li>
                        Objective 4 – System-level simulator. Develop RIS-tailored ray tracing modules and build the first open access simulation (software) platform to analyze, optimize, and test large-scale RIS-based intelligent radio environments.
                    </li>

                </ul> */}
            </Box>


        </Box>
    )
}

export default HomeRight