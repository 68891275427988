import React from "react";
import { Box, IconButton, Link } from "@mui/material";

import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const SocialsList = ({ containerStyle, size, iconColor = "#7d7d7d" }) => {

    const socialMenuList = [
        {
            id: '1',
            text: 'Linkedin',
            icon: <LinkedInIcon sx={{ color: iconColor }} />,
            link: 'https://www.linkedin.com/in/scion-project/',
            color: '#2e6cfb',
        },
        {
            id: '2',
            text: 'Twitter',
            icon: <TwitterIcon sx={{ color: iconColor }} />,
            link: 'https://twitter.com/ScionProject',
            color: '#3b9dd6',
        },
        {
            id: '3',
            text: 'Youtube',
            icon: <YouTubeIcon sx={{ color: iconColor }} />,
            link: 'https://www.youtube.com/channel/UCYjhNX5uofSGnV3_Qn9u8NQ',
            color: '#bc1d1d',
        },
        {
            id: '3',
            text: 'Instagram',
            icon: <InstagramIcon sx={{ color: iconColor }} />,
            link: 'https://www.instagram.com/scion_project/',
            color: '#b52d7c',
        },
    ]


    const renderItem = (item, index) => {
        return (
            <Link
                target="_blank"
                href={item?.link}
                key={index}
            >
                <IconButton
                    sx={{
                        backgroundColor: 'rgba(204, 204, 204, 0.2)',
                        width: size ?? '40px',
                        height: size ?? '40px',
                        '&>svg': {
                            width: size ? (size / 40) * 25 : '25px',
                            height: size ? (size / 40) * 25 : '25px',
                        },
                        '&:hover': {
                            backgroundColor: "rgba(38, 33, 140, 0.2)",
                            color: item?.color,
                        },
                        '&:hover > svg': {
                            color: item?.color,
                        },
                    }}
                >
                    {item?.icon}
                </IconButton>
            </Link>
        );
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                width: '200px',
                ...containerStyle
            }}
        >
            {socialMenuList?.map(renderItem)}
        </Box>
    );
};

export default SocialsList;
