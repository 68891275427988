import {
    News1Image1,
    News1Image2,
} from 'assets/images/news'


const news = [
    {
        id: '0',
        title: 'SCION f2f Kick-off Meeting',
        // desc: 'SCION f2f Kick-off Meeting will be organized on 3th April 2023 at Izmir Institute of Technology, İzmir, Türkiye. 10 PhD Positions were announced at https://euraxess.ec.europa.eu/jobs/61298 SCION Virtual Kick-off Meeting was held on Monday, 20 Feb. 2023.',
        desc: 'SCION f2f Kick-off Meeting was held on 3th April 2023 at Izmir Institute of Technology, İzmir, Türkiye.',
        imgUrl: News1Image1,
        link: 'https://euraxess.ec.europa.eu/jobs/61298',
        time: '3 April 2023',
        images: [
            {
                title: 'image1',
                imgUrl: News1Image1
            },
            {
                title: 'image3',
                imgUrl: News1Image2
            },

        ],
    },
    {
        id: '1',
        title: '10 PhD Positions were announced',
        desc: '10 PhD Positions were announced at https://euraxess.ec.europa.eu/jobs/61298',
        imgUrl: 'https://picsum.photos/300/250',
        images: [
            // {
            //     title: 'image1',
            //     imgUrl: 'https://i.pinimg.com/originals/a0/c2/2b/a0c22b34dacb4d2c76bd0fe27f833a2c.jpg'
            // },
            {
                title: 'image3',
                imgUrl: 'https://umit.net/wp-content/uploads/2021/11/rbcxo9au-u-1.jpg'
            },
            {
                title: 'image2',
                imgUrl: 'https://www.gaste24.com/images/album/26_1919.jpg'
            },
        ],
        time: '20 Feb. 2023.',
    },
]
// export const lastNews = [
//     {
//         id: '0',
//         title: 'SCION f2f Kick-off Meeting',
//         // desc: 'SCION f2f Kick-off Meeting will be organized on 3th April 2023 at Izmir Institute of Technology, İzmir, Türkiye. 10 PhD Positions were announced at https://euraxess.ec.europa.eu/jobs/61298 SCION Virtual Kick-off Meeting was held on Monday, 20 Feb. 2023.',
//         desc: 'SCION f2f Kick-off Meeting was held on 3th April 2023 at Izmir Institute of Technology, İzmir, Türkiye.',
//         imgUrl: News1Image1,
//         link: 'https://euraxess.ec.europa.eu/jobs/61298',
//         time: '3 April 2023',
//         images: [
//             {
//                 title: 'image1',
//                 imgUrl: News1Image1
//             },
//             {
//                 title: 'image3',
//                 imgUrl: News1Image2
//             },

//         ],
//     },
//     // {
//     //     id: '1',
//     //     title: '10 PhD Positions were announced',
//     //     desc: '10 PhD Positions were announced at https://euraxess.ec.europa.eu/jobs/61298 SCION Kick-off Meeting was held on Monday, 20 Feb. 2023.',
//     //     imgUrl: 'https://picsum.photos/300/250',
//     //     images: [
//     //         // {
//     //         //     title: 'image1',
//     //         //     imgUrl: 'https://i.pinimg.com/originals/a0/c2/2b/a0c22b34dacb4d2c76bd0fe27f833a2c.jpg'
//     //         // },
//     //         {
//     //             title: 'image3',
//     //             imgUrl: 'https://umit.net/wp-content/uploads/2021/11/rbcxo9au-u-1.jpg'
//     //         },
//     //         {
//     //             title: 'image2',
//     //             imgUrl: 'https://www.gaste24.com/images/album/26_1919.jpg'
//     //         },
//     //     ],
//     //     time: '20 Feb. 2023.',
//     // },
// ]

export default news