import React from 'react'
import { Box, Typography } from '@mui/material'
import { EuropeFlagImage } from 'assets'


const widgedOneList = [
    {
        text1: 'Call',
        text2: 'HORIZON-MSCA-2021-DN',
    },
    {
        text1: 'Type of Action',
        text2: 'MSCA Doctoral Networks',
    },
    {
        text1: 'Acronym',
        text2: 'SCION',
    },
    {
        text1: 'GA Number',
        text2: '101072375',
    },
    {
        text1: 'Coordinator',
        text2: 'IYTE (Izmir Institute of Technology, Türkiye)',
    },
    {
        text1: 'Coordinator person',
        text2: 'Prof. Berna Özbek',
    },
    {
        text1: 'EU contribution',
        text2: '1 828 303,20 Euro',
    },
    {
        text1: 'Project start date',
        text2: 'February 1, 2023',
    },
    {
        text1: 'Duration',
        text2: '48 months',
    },
]


const WidgedOne = () => {
    return (
        <Box
            sx={{
                mb: '50px'
            }}
        >
            <Typography
                sx={{
                    fontFamily: 'Poppins-Semibold',
                    fontSize: '18px',
                    lineHeight: '22px',
                    color: '#222',
                    pb: '10px',
                    mb: '10px',
                    borderBottom: '1px solid #ccc'
                }}
            >
                Project Data
            </Typography>
            <Typography
                sx={{
                    fontFamily: 'Poppins-Regular',
                    fontSize: '12px',
                    lineHeight: '18px',
                    color: '#666',
                    textAlign: 'justify',
                }}
            >
                SCION - Secured and Intelligent Massive
                Machine-to-Machine Communications for 6G is
                a multi-partner Doctoral Network (DN) project,
                within the framework of the Horizon Europe
                Marie Skłodowska-Curie Actions (MSCA).
            </Typography>

            <Box sx={{ width: '200px', flex: '0 0 200px', m: 'auto', my: '10px' }}>
                <img src={EuropeFlagImage} alt="Europe Flag" style={{ width: '100%', height: '100%' }} />
            </Box>

            <ul style={{ marginLeft: '20px' }}>
                {
                    widgedOneList?.map((item, index) => {


                        return (
                            <li
                                key={index}
                                style={{
                                    fontFamily: 'Poppins-Regular',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    color: '#666',
                                    textAlign: 'justify',
                                }}
                            >
                                <span
                                    style={{
                                        fontFamily: 'Poppins-Medium',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        color: '#000',
                                    }}
                                >
                                    {item?.text1} :
                                </span> &nbsp;
                                {item?.text2}
                            </li>
                        )
                    })
                }

            </ul>


        </Box>
    )
}

export default WidgedOne