import { Box, Container, Grid, IconButton, Typography } from '@mui/material';
import React, { useRef } from 'react'
import Slider from 'react-slick';

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { SliderImageOne } from 'assets';
import { SliderImageTwo } from 'assets';


const HomeSlider = () => {

    const sliderRef = useRef()

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const handleNextSlide = () => {
        sliderRef?.current?.slickNext();
    }

    const handlePrevSlide = () => {
        sliderRef?.current?.slickPrev();
    }

    const renderCustomArrows = () => {

        return (
            <Box
                sx={{
                    width: '100%',
                    position: 'absolute',
                    top: '45%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >

                <IconButton onClick={handlePrevSlide}>
                    <ArrowBackIosRoundedIcon />
                </IconButton>

                <IconButton onClick={handleNextSlide}>
                    <ArrowForwardIosRoundedIcon />
                </IconButton>

            </Box>
        )
    }

    const Item = ({ img }) => {
        return (
            <Box
                sx={{
                    // height: '250px',
                    display: 'flex  !important',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: 3,
                    alignItems: 'center',
                    // justifyContent: 'center',
                    pr: { md: '30px' }
                }}
            >
                <Box
                    sx={{
                        width: { xs: 'auto', md: '500px' },
                        flex: { md: '0 0 500px' },
                        height: '100%',
                    }}
                >
                    <img src={img} alt="" style={{ width: '100%', height: '100%' }} />
                </Box>

                <Typography
                    sx={{
                        fontFamily: 'Poppins-Regular',
                        textAlign: 'justify',
                    }}
                >
                    SCION - Secured and Intelligent Massive Machine-to-Machine
                    Communications for 6G is a multi-partner Doctoral Network
                    (DN) project, within the framework of the Horizon
                    Europe Marie Skłodowska-Curie Actions (MSCA).
                </Typography>

            </Box>
        )
    }

    return (
        <Box sx={{ pb: '50px' }}>
            <Container maxWidth="lg">

                <Box
                    sx={{
                        minHeight: '300px',
                        position: 'relative'
                    }}
                >
                    <Box
                        sx={{
                            px: { xs: '20px', md: '50px' },
                            py: { xs: '20px', md: '50px' },
                            // boxShadow: '1px 1px 10px 0px #ccc',
                            borderRadius: '8px',
                            pb: { xs: '50px', md: '0px' },

                        }}
                    >
                        <Slider {...settings} ref={sliderRef}>

                            <Item img={SliderImageTwo} />
                            <Item img={SliderImageOne} />

                        </Slider>
                    </Box>

                    {renderCustomArrows()}
                </Box >

            </Container >
        </Box >
    )
}

export default HomeSlider