import React from 'react'
import { Box, Link, Typography } from '@mui/material'

import {
    BernaOzbekImage,
    LeilaMusavian,
    SimayYılmazImage,
} from 'assets'

import { AppLayout } from 'layouts'
import { ManagementTeamCard } from 'components'

const managementTeamList = [
    {
        imgUrl: BernaOzbekImage,
        title: 'General Coordinator',
        name: 'Prof. Berna ÖZBEK',
        description: 'She is currently a Professor in telecommunication engineering with the Department of Electrical and Electronics Engineering, Izmir Institute of Technology, Türkiye, and working in the field of wireless communication systems for more than 15 years. Under her supervision, 15 master’s thesis and two Ph.D. dissertations have been completed. She has been awarded as the Marie-Curie Intra European (EIF) Fellow by the European Commission for two years, in 2010. She has coordinated one international and four national projects, worked as a Consultant for three Eureka-Celtic projects and two national industry driven projects. She is also supervising three Ph.D. and one master’s students, conducting one international project under H2020-MSCA-RISE, from 2018 to 2023, and coordinating an international project under Horizon Europe MSCA-DN, from 2022 to 2027. She has published more than 100 peer-reviewed articles, one book, one book chapter, and two patents. Her research interests include interference management, resource allocation, limited feedback links, device-to-device communications, physical layer security, massive MIMO systems, and mmWave communications.',
        link: 'http://www.linkedin.com'
    },
    {
        imgUrl: LeilaMusavian,
        title: 'Scientific Coordinator',
        name: 'Prof. Leila Musavian',
        description: 'She is currently working as a Professor of Wireless Communications at University of Essex. She received the Ph.D. degree in telecommunications from the Centre for Telecommunications Research (CTR), Kings College London, U.K. She was the Deputy Pro-Vice-Chancellor for research at the University of Essex from September 2018 to December 2020 and a Reader in telecommunications at the School of Computer Science and Electronic Engineering from December 2016 to October 2020. Prior to that, she was a Post-Doctoral Fellow at INRS-EMT, Canada, from 2006 to 2008; a Research Associate at Loughborough University, U.K., from 2009 to 2010; a Research Associate at McGill University from 2011 to 2012; a Lecturer at the InfoLab21, Lancaster University, from December 2012 to August 2016; and a Senior Lecturer at InfoLab21, Lancaster University, from August 2016 to November 2016. Her research interests lie in radio resource management for 6G/B5G communications, low latency communications, machine learning for communications, mmWave communications, massive MIMO, and energy harvesting communications. ',
        link: 'http://www.linkedin.com'
    },
    {
        imgUrl: SimayYılmazImage,
        title: 'General Coordinator Assistant',
        name: 'Simay Yılmaz',
        description: 'She received the B.S. degree from the Department of Electrical and Electronics, the degree from the Department of Chemistry, Izmir Institute of Technology, in 2014, and the M.S. degree from the Department of Electrical and Electronics, Izmir Institute of Technology, in 2017, where she is currently pursuing the Ph.D. degree. She is also working as a Research Assistant with the Department of Electrical and Electronics Engineering, Izmir Institute of Technology. During the M.S. program, she worked on a project supported by the Republic of Turkey Ministry of Science, Industry and Technology She is also working as a researcher in an international project supported by European Union under Horizon 2020-MSCA-RISE. Her current research interests include massive MIMO systems and mobile edge computing.',
        link: 'http://www.linkedin.com'
    },
]


const ManagementTeam = () => {
    return (
        <AppLayout style={{ mb: '50px' }}>
            <Typography
                sx={{
                    fontFamily: 'Poppins-Semibold',
                    fontSize: '20px',
                    lineHeight: '24px',
                    color: '#222',
                    mb: '20px',
                    textTransform: 'uppercase',
                    textAlign: { xs: 'center', md: 'left' }
                }}
            >
                Management Team
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                }}
            >
                {
                    managementTeamList?.map((item, index) => {

                        return (
                            <ManagementTeamCard
                                key={index}
                                item={item}
                            />
                        )
                    })
                }
            </Box>

        </AppLayout>
    )
}

export default ManagementTeam