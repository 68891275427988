import React, { useEffect } from 'react'
import { Box, Container, Fab, Fade, Grid, useScrollTrigger } from '@mui/material'

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import Header from './header/Header'
import Footer from './footer/Footer'
import SideWidgets from './sideWidgeds/SideWidgets'


const AppLayout = ({ children, homeLayout = false, style }) => {

    const url = window.location.pathname
    // console.log(url)

    const handleScrollTop = (event) => {
        const anchor = (event?.target?.ownerDocument || document).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        handleScrollTop()
    }, [url]);

    return (
        <Box
            sx={{
                minHeight: '100vh',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                overflow: 'hidden',
            }}
        >
            <Header />

            <Box sx={{ flexGrow: 1, pt: '150px', }} >

                {
                    homeLayout ? (
                        <Box sx={style}>
                            {children}
                        </Box>
                    ) : (
                        <Container maxWidth="lg" sx={{ flexGrow: 1, ...style }} >
                            <Grid container sx={{ height: '100%' }} spacing={5}>
                                <Grid item xs={12} md={9}>
                                    {children}
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <SideWidgets />
                                </Grid>
                            </Grid>
                        </Container>
                    )
                }

            </Box>

            <ScrollTop />

            <Footer />
        </Box>
    )
}


function ScrollTop() {

    const trigger = useScrollTrigger();

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
                behavior: "smooth",
            });
        }
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: '30px', right: '30px' }}
            >
                <Fab size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </Box>
        </Fade>
    );
}


export default AppLayout