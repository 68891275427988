import { Box, Link, Typography } from '@mui/material'
import React from 'react'

const ManagementTeamCard = ({ item }) => {
    return (
        <Box
            sx={{
                border: '1px solid #ccc',
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: '20px',
            }}
        >

            <Box
                sx={{
                    // border: '1px solid #ccc',
                    maxHeight: '300px',
                    borderRight: '1px solid #ccc',
                    borderBottom: '1px solid #ccc',
                    pt: { xs: '20px', sm: '0' }
                }}
            >
                <Box sx={{ width: '200px', flex: '0 0 200px', m: 'auto', }}>
                    <img src={item?.imgUrl} alt={item?.name} style={{ width: '100%', height: '100%' }} />
                </Box>
                <Typography
                    sx={{
                        fontFamily: 'Poppins-Regular',
                        fontSize: '16px',
                        lineHeight: '22px',
                        color: '#222',
                        textAlign: 'center',
                        my: '20px',
                    }}
                >
                    {item?.name}
                </Typography>
            </Box>

            <Box sx={{ p: '20px' }}>

                <Typography
                    sx={{
                        fontFamily: 'Poppins-Medium',
                        fontSize: '18px',
                        lineHeight: '22px',
                        color: '#222',
                        pb: '20px',
                    }}
                >
                    {item?.title}
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Poppins-Light',
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: '#222',
                        pb: '20px',
                        textAlign: 'justify',
                    }}
                >
                    {item?.description}
                </Typography>

                {/* <Box sx={{ fontFamily: 'Poppins-Regular', fontSize: '14px' }}>
                    Read More:&nbsp;
                    <Link href={item?.link} target="_blank">
                        {item?.link}
                    </Link>
                </Box> */}
            </Box>

        </Box>
    )
}

export default ManagementTeamCard