import React from 'react'
import { Link as RouterLink } from 'react-router-dom';
import { Box, Accordion, AccordionDetails, AccordionSummary, Typography, Link } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AppLayout } from 'layouts';
import { useDataContext } from 'data';
import { NewsCardOne } from 'components';




const News = () => {

    const [expanded, setExpanded] = React.useState(false);
    const { news } = useDataContext()



    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const newList = [...news]

    return (
        <AppLayout style={{ mb: '50px' }}>
            <Typography
                sx={{
                    fontFamily: 'Poppins-Semibold',
                    fontSize: '20px',
                    lineHeight: '24px',
                    color: '#222',
                    mb: '20px',
                    textTransform: 'uppercase',
                    textAlign: { xs: 'center', md: 'left' }
                }}
            >
                News
            </Typography>

            {
                newList?.map((item, index) => {

                    return (
                        <NewsCardOne
                            key={index}
                            item={item}
                            index={index}
                            expanded={expanded}
                            handleChange={handleChange}
                        />
                    )
                })
            }

        </AppLayout>
    )
}

export default News