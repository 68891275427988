import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Container, Grid, Typography, Link } from '@mui/material'

import { footerMenuList } from 'routers/routerSettings'
import { LogoComponentOne, SocialsList } from 'components'
import { LogoImageWhite } from 'assets'


const styles = {
    title: {
        fontFamily: 'Poppins-Medium',
        fontSize: { xs: '16px' },
        color: '#fff',
        textAlign: 'justify',
        mb: '20px'
    }
}

const Footer = () => {

    const handleScrollTop = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
                behavior: "smooth",
            });
        }
    };

    const renderAdress = () => (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                textAlign: { xs: 'center', sm: 'left', }
            }}
        >
            <Box>
                <Typography sx={{ ...styles.title, textAlign: { xs: 'center', sm: 'left', } }} >
                    Our Contact
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Poppins-Regular',
                        fontSize: { xs: '12px' },
                        color: '#fff',
                        textAlign: 'justify',
                        textAlign: { xs: 'center', sm: 'left', }
                    }}
                >
                    Izmir Institute of Technology, Gulbahce, Urla,Izmir,35430, Türkiye
                </Typography>
            </Box>


            <SocialsList
                size={30}
                iconColor='#fff'
                containerStyle={{
                    justifyContent: { xs: 'center', sm: 'left' },
                    m: { xs: 'auto', sm: '0', },
                    mt: { xs: '20px', sm: '0', },
                }}
            />

        </Box>
    )

    const renderFooterMenu = (list) => (
        <Box>
            <Typography
                sx={{
                    ...styles.title,
                    textAlign: 'center',
                }}
            >
                {list?.title}
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    textAlign: 'center',
                }}
            >
                {
                    list?.items?.map((item, index) => (
                        <Link
                            key={item?.id ?? index}
                            to={item?.link}
                            onClick={handleScrollTop}
                            component={RouterLink}
                            sx={{
                                fontFamily: 'Poppins-Regular',
                                fontSize: { xs: '12px' },
                                color: '#fff',
                            }}
                        >
                            {item?.text}
                        </Link>
                    ))
                }
            </Box>

        </Box>
    )


    return (
        <Box
            sx={{
                // minHeight: '200px',
                // backgroundColor: '#3139A9',
                backgroundColor: '#181f88',
                // backgroundColor: '#4c4c4c',
                py: { xs: '50px' },
                // pb: { xs: '100px' },
            }}
        >
            <Container maxWidth="lg">

                <Grid container spacing={5}>

                    <Grid item xs={12} sm={6} md={3} >
                        <Box>
                            <LogoComponentOne
                                logo={LogoImageWhite}
                                styles={{
                                    width: { xs: '200px', sm: '150px', md: '200px' },
                                    m: 'auto',
                                }}
                            />

                            <Typography
                                sx={{
                                    fontFamily: 'Poppins-Regular',
                                    fontSize: { xs: '14px' },
                                    color: '#fff',
                                    textAlign: 'justify',
                                }}
                            >
                                SCION - Secured and Intelligent Massive Machine-to-Machine
                                Communications for 6G is a multi-partner Doctoral Network
                                (DN) project, within the framework of the Horizon Europe
                                Marie Skłodowska-Curie Actions (MSCA).
                            </Typography>
                        </Box>

                    </Grid>

                    <Grid item xs={12} sm={6} md={3} sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}>
                        {renderAdress()}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>

                                {renderFooterMenu(footerMenuList.col1)}

                            </Grid>
                            <Grid item xs={12} sm={6}>

                                {renderFooterMenu(footerMenuList.col2)}


                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} sx={{ display: { xs: 'block', sm: 'none', md: 'block' } }}>
                        {renderAdress()}
                    </Grid>


                </Grid>

            </Container>

        </Box>
    )
}

export default Footer