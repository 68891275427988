import React from 'react'
import { Box } from '@mui/material'
import WidgedOne from './_WidgedOne'
import WidgedTwo from './_WidgedTwo'

const SideWidgets = () => {
    return (
        <Box>
            <WidgedOne />
            <WidgedTwo />
        </Box>
    )
}

export default SideWidgets