import { Box, Card, Button, CardActionArea, CardActions, CardMedia, CardContent, Typography } from '@mui/material'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

const sampleCard = {
    imgUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Unknown_person.jpg/925px-Unknown_person.jpg'
}


const PersonCardOne = ({ item, index, handleSelect }) => {
    return (
        <Card height="100%" onClick={() => !item?.fullName?.includes("DC") && handleSelect(item)}>

            <CardActionArea sx={{ cursor: "default" }}>
                <Box
                    // to="/person-detail/name"
                    // component={RouterLink}
                    sx={{
                        textDecoration: 'none',
                    }}
                >
                    <CardMedia
                        component="img"
                        height="220"
                        image={item?.imgUrl ?? sampleCard?.imgUrl}
                        alt="green iguana"
                    />
                </Box>
            </CardActionArea>

            <CardContent
                sx={{
                    textAlign: 'center',
                    height: { xs: 130, md: 170 },
                    alignItems: 'center',
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    gutterBottom
                    variant="h6"
                    height={"100px"}
                    component="div"
                    sx={{
                        fontFamily: 'Poppins-Semibold'
                    }}
                >
                    {item.fullName}
                </Typography>
                <Typography
                    variant="body2"
                    fontSize={{ xs: 16, md: 14 }}
                    color="text.secondary"
                    sx={{
                        fontFamily: 'Poppins-Medium'
                    }}
                >
                    {item?.id < 7 ? item.enrollment : item.hostInstitution}
                </Typography>
            </CardContent>

        </Card>
    )
}

export default PersonCardOne