import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import RotateRightRoundedIcon from '@mui/icons-material/RotateRightRounded';

import { AppLayout } from 'layouts'
import { useDataContext } from 'data';


const images = [

]

const NewsDetail = () => {

    const { name } = useParams()
    const { news } = useDataContext()
    const [selectedNews, setSelectedNews] = React.useState(null)

    useEffect(() => {
        const newNews = news?.find(item => {

            console.log({ item: item?.title?.toLowerCase()?.split(" ")?.join("-"), name })
            return item?.title?.toLowerCase()?.split(" ")?.join("-") === name
        })
        console.log({ newNews })
        setSelectedNews(newNews)
    }, [news, name]);


    return (
        <AppLayout>
            <Typography
                sx={{
                    fontFamily: 'Poppins-Semibold',
                    fontSize: '20px',
                    lineHeight: '24px',
                    color: '#222',
                    mb: '20px',
                    textTransform: 'uppercase',
                    textAlign: { xs: 'center', md: 'left' }
                }}
            >
                News : {name}
            </Typography>

            <Typography
                style={{
                    fontFamily: 'Poppins-Regular',
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: '#666',
                    textAlign: 'justify',
                    marginBottom: '30px',
                }}
            >
                {/* <span
                                    style={{
                                        fontFamily: 'Poppins-Medium',
                                        // fontSize: '16px',
                                        // lineHeight: '18px',
                                        color: '#000',
                                    }}
                                >
                                    {item?.text1} :
                                </span> &nbsp; */}
                {selectedNews?.desc}

            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <PhotoProvider
                    speed={() => 800}
                    // easing={(type) => 'cubic-bezier(0.34, 1.56, 0.64, 1)'}
                    easing={(type) => 'cubic-bezier(0.34, 1.56, 0.64, 1)'}
                    toolbarRender={({ rotate, onRotate, onScale, scale }) => {
                        return (
                            <Box
                                sx={{
                                    height: '50px',

                                    '&  *': {
                                        color: '#fff',
                                        height: '50px',
                                        width: '50px',
                                    }
                                }}
                            >
                                {/* <svg className="PhotoView-Slider__toolbarIcon" onClick={() => onScale(scale + 1)} />
                                <svg className="PhotoView-Slider__toolbarIcon" onClick={() => onScale(scale - 1)} /> */}
                                <RotateRightRoundedIcon className="PhotoView-Slider__toolbarIcon" onClick={() => onRotate(rotate + 90)} />

                            </Box>
                        );
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: '20px',
                        }}
                    >
                        {
                            selectedNews?.images?.map((item, index) => (
                                <PhotoView key={item?.title} src={item?.imgUrl}>
                                    <Box sx={{ width: '150px', }}>
                                        <img src={item?.imgUrl} alt="" style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                                    </Box>
                                </PhotoView>
                            ))
                        }
                    </Box>
                </PhotoProvider>
            </Box>


        </AppLayout>
    )
}

export default NewsDetail