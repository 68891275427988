import React, { useState } from 'react'
import { Box, Typography } from '@mui/material';

import { NewsCardOne } from 'components';
import { useDataContext } from 'data';



const HomeAccordion = () => {

    const [expanded, setExpanded] = useState(false);
    const { news } = useDataContext()

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box
            sx={{
                width: '100%',
            }}
        >

            <Typography
                sx={{
                    fontFamily: 'Poppins-Semibold',
                    fontSize: '18px',
                    lineHeight: '22px',
                    color: '#222',
                    mb: '20px',
                }}
            >
                Last News
            </Typography>

            {
                news?.slice(0, 3)?.map((item, index) => {

                    return (
                        <NewsCardOne
                            key={index}
                            item={item}
                            index={index}
                            expanded={expanded}
                            handleChange={handleChange}
                        />
                    )
                })
            }


        </Box>
    )
}

export default HomeAccordion