import { Box } from '@mui/material';
import AppRouter from "./routers/AppRouter";

function App() {

  return (
    <Box className="App">
      <AppRouter />

    </Box>
  );
}

export default App;
