import { Typography } from '@mui/material'
import { AppLayout } from 'layouts'
import React from 'react'

const objectivesList = [
    {
        text1: 'RO1',
        text2: 'To research and develop semantic encoder and decoder for M2M communication',
    },
    {
        text1: 'RO2',
        text2: 'To research and develop highly efficient and simultaneous wireless power and information transfer technologies and achieve sustainable communication in the M2M communication network.',
    },
    {
        text1: 'RO3',
        text2: 'To research and propose lightweight AI model design approaches and online learning methods to enhance the M2M communication network significantly and overcome network complexity and reliability challenges. ',
    },
    {
        text1: 'RO4',
        text2: 'To research and propose ultra-reliable and autonomous network management strategies to achieve a robust IoT network with self-organization and self-healingcapabilities.',
    },
    {
        text1: 'RO5',
        text2: 'To research and adopt the Digital Twin (DT) technology for establishing digital representations of M2M communication to provide accurate decision-making guidance for allocating network resources.',
    },
    {
        text1: 'RO6',
        text2: 'To research and propose an AI-based M2M communication scheduling to optimize the Age of Information (AoI) to guarantee the information freshness of environmental monitoring data.',
    },
    {
        text1: 'RO7',
        text2: 'To research and develop a blockchain framework for DTs (virtual world) and M2M (physical world) to ensure transparency, trust, and security.',
    },
    {
        text1: 'RO8',
        text2: 'To develop a complete and practically deployable project system by integrating the individual innovations and evaluating the system via extensive experiments using measurements from real-world scenario.',
    },
]


const Objectives = () => {
    return (
        <AppLayout>

            <Typography
                sx={{
                    fontFamily: 'Poppins-Semibold',
                    fontSize: '20px',
                    lineHeight: '24px',
                    color: '#222',
                    mb: '20px',
                    textTransform: 'uppercase',
                    textAlign: { xs: 'center', md: 'left' }
                }}
            >
                Objectives
            </Typography>

            <ul style={{ listStyle: 'none', }}>
                {
                    objectivesList?.map((item, index) => {


                        return (
                            <li
                                key={index}
                                style={{
                                    fontFamily: 'Poppins-Regular',
                                    fontSize: '16px',
                                    lineHeight: '22px',
                                    color: '#666',
                                    textAlign: 'justify',
                                    marginBottom: '5px',
                                }}
                            >
                                <span
                                    style={{
                                        fontFamily: 'Poppins-Medium',
                                        // fontSize: '16px',
                                        // lineHeight: '18px',
                                        color: '#000',
                                    }}
                                >
                                    {item?.text1} :
                                </span> &nbsp;
                                {item?.text2}
                            </li>
                        )
                    })
                }
            </ul>

        </AppLayout>
    )
}

export default Objectives