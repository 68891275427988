import React from 'react'
import { Box, Typography } from '@mui/material'

import {
    AverioLogo,
    IrisAutoLogo,
    UessexLogo,

    DataMagnumLogo,
    HuaweiLogo,
    IyteLogo,
    KthLogo,
    TueLogo,
    DataMachineEliteLogo
} from 'assets' 
import { AppLayout } from 'layouts'
import { PartnerCardOne } from 'components'

const partnersList1 = [
    {
        name: 'Izmir Institute of Technology (IYTE)',
        country: 'Türkiye',
        imgUrl: IyteLogo,
        companyWebLink: 'https://en.iyte.edu.tr',
    },
    {
        name: 'Kungliga Tekniska Högskolan (KTH)',
        country: 'Sweden',
        imgUrl: KthLogo,
        companyWebLink: 'https://www.kth.se/en',
    },
    // {
    //     name: 'Data Magnum Europa LDA (DM Europa)',
    //     country: 'Portugal',
    //     imgUrl: DataMagnumLogo,
    //     companyWebLink: 'https://www.data-magnum.com',
    // },
    {
        name: 'Data Machine Elite Ltd',
        country: 'Portugal',
        imgUrl: DataMachineEliteLogo,
        companyWebLink: 'https://www.datamachineelite.com/',
    },
    {
        name: 'Eindhoven University of Technology (TU/e)',
        country: 'Netherlands',
        imgUrl: TueLogo,
        companyWebLink: 'https://www.tue.nl/en/',
    },
    {
        name: 'Huawei Technologies (HWDU), Munich',
        country: 'Germany',
        imgUrl: HuaweiLogo,
        companyWebLink: 'https://www.huawei.com/en/',
    },
]

const partnersList2 = [
    {
        name: 'Iris Automation Ltd. (IRIS)',
        country: 'United Kingdom',
        imgUrl: IrisAutoLogo,
        companyWebLink: 'https://iris-auto.com/',
    },
    {
        name: 'University of Essex (UESSEX)',
        country: 'United Kingdom',
        imgUrl: UessexLogo,
        companyWebLink: 'https://www.essex.ac.uk',
    },
    {
        name: 'University of Aveiro',
        country: 'Portugal',
        imgUrl: AverioLogo,
        companyWebLink: 'https://www.ua.pt/en/',
    },
]


const Partners = () => {
    return (
        <AppLayout styles={{ mb: '50px' }}>
            <Typography
                sx={{
                    fontFamily: 'Poppins-Semibold',
                    fontSize: '20px',
                    lineHeight: '24px',
                    color: '#222',
                    mb: '20px',
                    textTransform: 'uppercase',
                    textAlign: { xs: 'center', md: 'left' }
                }}
            >
                Partners
            </Typography>

            <Typography
                sx={{
                    fontFamily: 'Poppins-Regular',
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#666',
                    mb: '20px',
                    // textAlign: { xs: 'center', md: 'left' }
                }}
            >
                {/* The SCION consists of 5 beneficiaries and 3 associated partners from UK and Portugal. The associated partners from UK are supported directly by UK goverment with  total budget is 0.9MEuro. */}
                The SCION consortium consists of 5 beneficiaries and 3 associated partners from United Kingdom (UK) and Portugal. The associated partners from UK, i.e., UESSEX and IRIS Automation Ltd., are funded by the UK Research and Innovation (UKRI) under the UK government’s Horizon Europe funding guarantee [grant numbers EP/X027201/1 and  EP/X027244/1].
            </Typography>

            <Box sx={{ mb: '30px' }}>
                <Typography
                    sx={{
                        fontFamily: 'Poppins-Semibold',
                        fontSize: '18px',
                        lineHeight: '22px',
                        color: '#222',
                        pb: '10px',
                        mb: '10px',
                        borderBottom: '1px solid #ccc'
                    }}
                >
                    Beneficiaries
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '20px',
                        alignContent: 'center',

                    }}
                >
                    {
                        partnersList1?.map((item, index) => {

                            return (
                                <PartnerCardOne key={index} item={item} />
                            )
                        })
                    }
                </Box>

            </Box>


            <Box sx={{ mb: '30px' }}>
                <Typography
                    sx={{
                        fontFamily: 'Poppins-Semibold',
                        fontSize: '18px',
                        lineHeight: '22px',
                        color: '#222',
                        pb: '10px',
                        mb: '10px',
                        borderBottom: '1px solid #ccc'
                    }}
                >
                    Associated Partners
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '20px',

                    }}
                >
                    {
                        partnersList2?.map((item, index) => {

                            return (
                                <PartnerCardOne key={index} item={item} />
                            )
                        })
                    }
                </Box>

            </Box>



        </AppLayout>
    )
}

export default Partners