import React from 'react'
import { Link as RouterLink } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Accordion, AccordionDetails, AccordionSummary, Typography, Link } from '@mui/material';


const NewsCardOne = ({ item, index, expanded, handleChange }) => {


    return (
        <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{
                my: '10px',
                borderRadius: '3px !important',
                border: '1px solid #ccc',
                boxShadow: 'none',
                width: '100%',
            }}
        >

            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{
                    backgroundColor: 'rgba(204, 204, 204, 0.3)',
                    width: '100%',
                }}
            >
                <Typography
                    sx={{
                        // width: '33%',
                        flexShrink: 0,
                        fontFamily: 'Poppins-Regular',
                        width: '100%',
                        fontSize: '0.9rem',
                        // whiteSpace: 'nowrap',
                    }}
                >
                    {item?.title?.slice(0, 40)}
                </Typography>
                {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
            </AccordionSummary>

            <AccordionDetails>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: '10px',
                        mb: '20px'
                    }}
                >
                    <Box sx={{ width: '100px', height: '100px', flex: '0 0 100px' }}>
                        <img src={item?.imgUrl} alt="" style={{ width: '100%', height: '100%' }} />
                    </Box>
                    <Typography
                        sx={{
                            fontFamily: 'Poppins-Regular',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#666',
                        }}
                    >
                        {item?.desc}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: '10px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Poppins-Regular',
                            fontSize: '12px',
                            lineHeight: '16px',
                            color: '#666',
                        }}
                    >
                        {item?.time}
                    </Typography>

                    <Link
                        // to={item?.link}
                        to={`/news-detail/${item?.title?.toLowerCase()?.split(' ').join('-')}`}
                        component={RouterLink}
                        sx={{
                            border: '1px solid #666',
                            p: { xs: '5px 8px', },
                            textDecoration: 'none',
                            borderRadius: '8px',
                            fontFamily: 'Poppins-Medium',
                            fontSize: '14px',
                            color: '#666',

                            '&:hover': {
                                // border: '1px solid #666',
                                backgroundColor: '#666',
                                color: '#fff',
                            }
                        }}
                    >
                        Read More
                    </Link>
                </Box>
            </AccordionDetails>

        </Accordion>
    )
}

export default NewsCardOne