import React from 'react'
import { Box, Typography } from '@mui/material'

import { AppLayout } from 'layouts'


const workpackagesList1 = [
    {
        text1: 'WP3',
        text2: 'Cyber-Physical System (CPS) Requirement Analysis and System Architecture Design (Lead: HWDU) (M7-M42)',
    },
    {
        text1: 'WP4',
        text2: 'Novel Design of Semantic Encoder and decoder (Lead: KTH) (M7-M42)',
    },
    {
        text1: 'WP5',
        text2: 'Intelligent Digital Twin and blockchain modelling and optimization (DM) (M7-M42)',
    },
    {
        text1: 'WP6',
        text2: 'Ultra-Reliable and AI-Driven Autonomous Network Management using AoI (TU/e) (M7-M42)',
    },
    {
        text1: 'WP7',
        text2: 'System Integration Test, demonstration, and Evaluation (IRIS) (M18-M42)',
    },
]

const workpackagesList2 = [
    {
        text1: 'WP1',
        text2: 'Ethics (IYTE) (M1-M48)',
    },
    {
        text1: 'WP2',
        text2: 'Project Management (IYTE) (M1-M48)',
    },
    {
        text1: 'WP8',
        text2: 'Training (UESSEX) (M7-M48)',
    },
    {
        text1: 'WP9',
        text2: 'Dissemination and Exploitation (UESSEX) (M7-M48)',
    },

]

const Workpackages = () => {

    return (
        <AppLayout>
            <Typography
                sx={{
                    fontFamily: 'Poppins-Semibold',
                    fontSize: '20px',
                    lineHeight: '24px',
                    color: '#222',
                    mb: '20px',
                    textTransform: 'uppercase',
                    textAlign: { xs: 'center', md: 'left' }
                }}
            >
                Workpackages
            </Typography>

            <Box sx={{ mb: '30px' }}>
                <Typography
                    sx={{
                        fontFamily: 'Poppins-Semibold',
                        fontSize: '18px',
                        lineHeight: '22px',
                        color: '#222',
                        pb: '10px',
                        mb: '10px',
                        borderBottom: '1px solid #ccc'
                    }}
                >
                    Technical WPs
                </Typography>

                <ul style={{ listStyle: 'none', }}>
                    {
                        workpackagesList1?.map((item, index) => {


                            return (
                                <li
                                    key={index}
                                    style={{
                                        fontFamily: 'Poppins-Regular',
                                        fontSize: '16px',
                                        lineHeight: '22px',
                                        color: '#666',
                                        textAlign: 'justify',
                                        marginBottom: '5px',
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: 'Poppins-Medium',
                                            // fontSize: '16px',
                                            // lineHeight: '18px',
                                            color: '#000',
                                        }}
                                    >
                                        {item?.text1} :
                                    </span> &nbsp;
                                    {item?.text2}
                                </li>
                            )
                        })
                    }
                </ul>
            </Box>

            <Box sx={{ mb: '30px' }}>
                <Typography
                    sx={{
                        fontFamily: 'Poppins-Semibold',
                        fontSize: '18px',
                        lineHeight: '22px',
                        color: '#222',
                        pb: '10px',
                        mb: '10px',
                        borderBottom: '1px solid #ccc'
                    }}
                >
                    Management WPs
                </Typography>
                <ul style={{ listStyle: 'none', }}>
                    {
                        workpackagesList2?.map((item, index) => {


                            return (
                                <li
                                    key={index}
                                    style={{
                                        fontFamily: 'Poppins-Regular',
                                        fontSize: '16px',
                                        lineHeight: '22px',
                                        color: '#666',
                                        textAlign: 'justify',
                                        marginBottom: '5px',
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: 'Poppins-Medium',
                                            // fontSize: '16px',
                                            // lineHeight: '18px',
                                            color: '#000',
                                        }}
                                    >
                                        {item?.text1} :
                                    </span> &nbsp;
                                    {item?.text2}
                                </li>
                            )
                        })
                    }
                </ul>
            </Box>
        </AppLayout>
    )
}

export default Workpackages