import { AppLayout } from 'layouts'
import React from 'react'
import { HomeMain, HomeSlider } from 'sections'


const Home = () => {
    return (
        <AppLayout homeLayout>

            <HomeSlider />
            <HomeMain />

        </AppLayout>
    )
}

export default Home