import { styled } from '@mui/material/styles';
import React, { useCallback, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Link, Tooltip, Zoom } from '@mui/material';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const LightTooltip = styled(({ className, ...props }) => (<Tooltip {...props} classes={{ popper: className }} />))(({ theme }) => null);


const NavbarMenuLink = ({ item, onClick = () => null }) => {

  const url = window.location.pathname
  const [menuDropdown, setMenuDropdown] = useState(null);
  const handleClose = () => {
    setMenuDropdown(false);
  };

  const handleOpen = () => {
    setMenuDropdown(true);
  };

  const renderDropdownMenu = useCallback(() => {

    return (
      <Box>

        {
          item?.items?.map((subItem, index) => {

            let isSubActive = false
            if (subItem.link === url) {
              isSubActive = true;
            }
            return (
              <Link
                key={subItem?.id ?? index}
                to={subItem?.link}
                component={RouterLink}
                onClick={onClick}
                sx={{
                  ...styles?.itemStyle,
                  fontSize: '14px',
                  borderRadius: '0px',
                  ...(isSubActive && {
                    backgroundColor: 'rgba(28, 28, 28, 0.1)',
                    color: '#000',
                  }),
                }}
              >
                {subItem?.text}
              </Link>
            )
          })
        }

      </Box>
    )
  })


  // ################  make active link function ###############
  const findIsActive = () => {
    if (url === item?.link) {
      // console.log(url, item?.link)
      return true;
    }

    if (item?.link === "/project") {
      if (url === "/objectives" || url === "/workpackages") {
        return true;
      }
    }

    if (item?.link === "/participants") {
      if (url === "/candidates" || url === "/partners" || url === "/managementTeam") {
        return true;
      }
    }

    return false
  }
  const isActive = findIsActive()


  // ################  styles ###############
  const styles = {
    itemStyle: {
      fontSize: '15px',
      fontFamily: 'Poppins-Medium',
      color: '#000',
      textDecoration: 'none',
      textTransform: 'uppercase',
      px: '10px',
      py: '10px',
      cursor: 'pointer',
      borderRadius: '8px',

      display: 'flex',
      // alignItems: 'center',
      width: { xs: '100%', lg: 'auto' },

      '&:hover': {
        backgroundColor: 'rgba(28, 28, 28, 0.1)',
        color: '#000',
      }
    }
  }



  // ################  dropdown link item ###############
  if (item?.items?.length > 0) {

    return (
      <Box sx={{ width: { xs: '100%', lg: 'auto', } }}>


        <LightTooltip
          disableFocusListener
          title={renderDropdownMenu(item)}
          placement="top-end"
          open={Boolean(menuDropdown)}
          onClose={handleClose}
          onOpen={handleOpen}
          TransitionComponent={Zoom}

          sx={{

            '& > .MuiTooltip-tooltip ': {
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              boxShadow: "1px 1px 5px 0px rgba(204, 204, 204, 0.76)",
              backdropFilter: "blur(8px)",
              position: 'relative',
              borderRadius: '8px',
              p: '0px',
              overflow: 'hidden',
              width: '100%',

            }

          }}
        >
          <Button
            sx={{
              ...styles?.itemStyle,
              ...(isActive && {
                backgroundColor: 'rgba(28, 28, 28, 0.1)',
                color: '#000',
              }),
              pr: '0px',
              justifyContent: 'space-between',
              flexGrow: 1,
              width: { xs: '100%', lg: 'auto' },
              height: '100%',
            }}
          // onClick={(event) => setMenuDropdown(event.currentTarget)}
          // onMouseOver={(event) => setMenuDropdown(event.currentTarget)}
          // onMouseLeave={(event) => setMenuDropdown(null)}
          >
            {item?.text}
            {menuDropdown ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </Button>
        </LightTooltip>

      </Box>
    )
  }

  // ################  normal link item ###############
  return (
    <Link
      to={item?.link}
      component={RouterLink}
      onClick={onClick}
      sx={{
        ...styles?.itemStyle,
        ...(isActive && {
          backgroundColor: 'rgba(28, 28, 28, 0.1)',
          color: '#000',
        }),
      }}
    >
      {item?.text}
    </Link>
  )

}

export default NavbarMenuLink