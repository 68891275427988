import React, { createContext, useContext } from 'react'

import news from './_news'

const DataContext = createContext()

const DataContextProvider = ({ children }) => {

    const values = {
        news,
    }

    return (
        <DataContext.Provider value={values}>
            {children}
        </DataContext.Provider>
    )
}

export const useDataContext = () => useContext(DataContext)
export default DataContextProvider