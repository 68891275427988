import React from 'react'
import {
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';


import { AppLayout } from 'layouts'


const trainingList = [
    {
        text1: 'Training Objective A',
        text2: 'Providing a solid understanding of RIS-based communications, at all levels, i.e. electromagnetics for RISs, theory and algorithms for RIS-based networks, simulation of RIS-based networks.',
    },
    {
        text1: 'Training Objective B',
        text2: 'Providing inter-sectoral experience, with training on both theoretical and applied research, as well as in standardization procedures, and the creation of intellectual property rights.',
    },
    {
        text1: 'Training Objective C',
        text2: 'Providing transferable skills training, (project management, entrepreneurship, gender issues, exploitation and dissemination of results, organization of outreach events, forming collaborations, etc.).',
    },


]

const styles = {
    tableHeadRow: {
        fontFamily: 'Poppins-Regular !important',
        backgroundColor: '#3139A9 !important',
    }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat };
}

const rows = [
    createData('1st SCION Workshop on Semantic Communication', "KTH", "18"),
    createData('2nd SCION Workshop on Cyber-Physical system', "HUW", "36"),
    createData('SCION Conference', "IYTE", "42"),
    createData('1st Industrial Dissemination Day event', "IRIS", "24"),
    createData('2nd Industrial Dissemination Day event', "HUW", "38"),
    createData('Course 1: Writing of scientific papers and presentation skills', "UESSEX", "8"),
    createData('Course 2: The theory and method of science ', "KTH", "12"),
    createData('Course 3: Patent writing and Standardization ', "HUW ", "18"),
    createData('Course 4: Regulation of the radio spectrum and network neutrality', "IRIS", "30"),
    createData('Course 5: Business planning', "DM", "33"),
    createData('Course 6: EU funding and execution skills', "TU/e", "36"),


    createData('Seminar 1: Ethics and gender issues', "UESSEX", "08"),
    createData('Seminar 2: 6G PHY layer aspects', "UESSEX", "12"),
    createData('Seminar 3: Recent advances in 6G communications', "TU/e", "15"),
    createData('Seminar 4: Digital Twin: State of the art and Future Perspectives', "IRIS", "18"),
    createData('Seminar 5: Analytical modelling and Simulation Tools', "HUW", "24"),
    createData('Seminar 6:  Semantic Communication', "KTH", "31"),
    createData('Seminar 7: System level modelling and implementation', "DM", "34"),
    createData('Seminar 8: ML Information Processing', "IYTE", "38"),
    createData('Demo Show and Open house day', "DM", "48"),
    createData('Activity Days 1', "TU/e", "21"),
    createData('Activity Days 2', "KTH", "28"),
    createData('Activity Days 3', "UESSEX", "35"),
    createData('Activity Days 4', "IYTE", "42"),
];

const Training = () => {
    return (
        <AppLayout style={{ mb: '50px' }}>

            <Typography
                sx={{
                    fontFamily: 'Poppins-Semibold',
                    fontSize: '20px',
                    lineHeight: '24px',
                    color: '#222',
                    mb: '20px',
                    textTransform: 'uppercase',
                    textAlign: { xs: 'center', md: 'left' }
                }}
            >
                Training
            </Typography>

            {/* <Typography
                sx={{
                    fontFamily: 'Poppins-Regular',
                    fontSize: '16px',
                    // lineHeight: '22px',
                    color: '#222',
                    mb: '20px',
                    textAlign: { xs: 'center', md: 'left' }
                }}
            >
                Alongside the research objectives, MetaWireless has the following main <b><i>training objectives</i></b> for the ESRs:
            </Typography> */}

            {/* <Box>
                <ul style={{ marginLeft: '20px' }}>
                    {
                        trainingList?.map((item, index) => {


                            return (
                                <li
                                    key={index}
                                    style={{
                                        fontFamily: 'Poppins-Regular',
                                        fontSize: '16px',
                                        lineHeight: '22px',
                                        color: '#666',
                                        textAlign: 'justify',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <span
                                        style={{
                                            fontFamily: 'Poppins-Medium',
                                            // fontSize: '16px',
                                            // lineHeight: '22px',
                                            color: '#000',
                                        }}
                                    >
                                        {item?.text1} :
                                    </span> &nbsp;
                                    {item?.text2}
                                </li>
                            )
                        })
                    }

                </ul>
            </Box> */}

            <Typography
                sx={{
                    fontFamily: 'Poppins-Regular',
                    fontSize: '16px',
                    // lineHeight: '22px',
                    color: '#666',
                    mb: '20px',
                    textAlign: { xs: 'justify', md: 'justify' }
                }}
            >
                {/* In particular, in addition to specificities one-day workshops, dissemination day and final conference, some Schools and Complementary courses will be organized. Schools, delivered by experts from industry/academia on specific topics of the project, will be organized to give to the ESRs the time to learn and use the material for their individual project, while Complementary courses supplement those offered in academia. */}
                {/* This this collaborative Doctoral Network (DN), SCION, will create a training network for DCs who will contribute to the design and implementation of future B5G/6G networks to become potential leaders in the resulting scientific, technological, and industrial fields. The DN is committed to creating an “excellent” research educational training platform that is multi-disciplinary and inter-sectoral in nature. The SCION consortium encompasses both industrial participants and top-level academic institutions, including universities and research institutes, with all partners having international reputations in the field of expertise related to wireless communications, networking, and Artificial Intelligence (AI). */}
                This this collaborative Doctoral Network (DN), SCION, will create a training network for DCs who will contribute to the design and implementation of future B5G/6G networks to become potential leaders in the resulting scientific, technological, and industrial fields. The DN is committed to creating an “excellent” research educational training platform that is multi-disciplinary and inter-sectoral in nature. The SCION consortium encompasses both industrial participants and top-level academic institutions, including universities and research institutes, with all partners having international reputations in the field of expertise related to wireless communications, networking, and Artificial Intelligence (AI).
            </Typography>

            <TableContainer component={Paper} sx={{ maxWidth: "900px", m: 'auto' }}>
                <Table aria-label="customized table">

                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={styles?.tableHeadRow}>Main Training Event & Conference</StyledTableCell>
                            <StyledTableCell align="right" sx={styles?.tableHeadRow}>Lead Institute</StyledTableCell>
                            <StyledTableCell align="right" sx={styles?.tableHeadRow}>Action Month (estimated)</StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rows.map((row) => (
                            <StyledTableRow key={row.name} hover >
                                <StyledTableCell component="th" scope="row" sx={{ fontFamily: 'Poppins-Regular !important' }}> {row.name} </StyledTableCell>
                                <StyledTableCell align="right" sx={{ fontFamily: 'Poppins-Regular !important' }}>{row.calories}</StyledTableCell>
                                <StyledTableCell align="right" sx={{ fontFamily: 'Poppins-Regular !important' }}>{row.fat}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>

        </AppLayout>
    )
}

export default Training