import React from 'react'
import { Box, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

import { LogoImageOne } from 'assets'


const LogoComponentOne = ({ styles, logo }) => {

    const handleScrollTop = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
                behavior: "smooth",
            });
        }
    };

    return (
        <Link
            to="/"
            onClick={handleScrollTop}
            component={RouterLink}
            sx={{
                fontFamily: 'Poppins-Regular',
                fontSize: { xs: '12px' },
                color: '#fff',
            }}
        >

            <Box
                sx={{
                    width: { xs: '100px', sm: '150px', md: '200px' },
                    // flex: 1,
                    ...styles
                }}
            >
                <img
                    src={logo ?? LogoImageOne}
                    alt="logo"
                    style={{
                        width: '100%',
                        height: '100%'
                    }}
                    loading='lazy'
                />
            </Box>
        </Link>
    )
}

export default LogoComponentOne