import React, { memo, useState } from 'react'
import {
    AppBar,
    Box,
    Container,
    Drawer,
    IconButton,
    MenuItem,
    Slide,
    Toolbar,
    Link,
    useScrollTrigger,
    Divider,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
// import { Link as RouterLink } from 'react-router-dom';

import { headerMenu } from 'routers/routerSettings';
import { LogoComponentOne, SocialsList, NavbarMenuLink } from 'components';
import NavbarMenuLinkMobile from 'components/linkitems/_NavbarMenuLinkMobile';



function HideOnScroll({ children }) {
    const trigger = useScrollTrigger();
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}


const Header = () => {

    const handleScrollTop = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
                behavior: "smooth",
            });
        }
    };

    return (
        <Box id="back-to-top-anchor">
            <HideOnScroll>
                <AppBar
                    sx={{
                        backgroundColor: '#fff',
                        py: 2,
                        boxShadow: '0px 0px 20px 0px #ccc',
                    }}
                >
                    <Container maxWidth="lg">
                        <Toolbar disableGutters>

                            <Box sx={{ display: 'flex' }}>
                                <LogoComponentOne />
                            </Box>

                            <Box
                                sx={{
                                    flexGrow: 1,
                                    // backgroundColor: 'red',
                                    display: { xs: 'none', lg: 'flex' },
                                    gap: '10px',
                                    ml: '20px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {headerMenu.map((item, index) => (
                                    <NavbarMenuLink
                                        key={item?.id ?? index}
                                        item={item}
                                        onClick={handleScrollTop}
                                    />
                                ))}
                            </Box>

                            <MobileMenu />

                            <SocialsList size={30} containerStyle={{ justifyContent: 'flex-end', display: { xs: 'none', lg: 'flex' }, }} />

                        </Toolbar>
                    </Container>
                </AppBar>
            </HideOnScroll>
        </Box>
    )
}


const MobileMenu = () => {

    const [isDrawerMenuOpen, setIsDrawerMenuOpen] = useState(false)

    // const handleScrollTop = (event) => {
    //     const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    //     if (anchor) {
    //         anchor.scrollIntoView({
    //             block: 'center',
    //             behavior: "smooth",
    //         });
    //     }
    // };

    const handleLinkClick = () => {
        setIsDrawerMenuOpen(false)
        // handleScrollTop()
    }

    return (
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' }, justifyContent: 'flex-end' }}>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setIsDrawerMenuOpen(true)}
                color="#000"
            >
                <MenuIcon />
            </IconButton>

            <Drawer
                anchor="left"
                open={isDrawerMenuOpen}
                onClose={() => setIsDrawerMenuOpen(false)}
            >
                <Box
                    sx={{
                        p: { xs: '10px' },
                        maxWidth: { xs: '250px' }
                    }}
                >

                    <LogoComponentOne styles={{ width: { xs: '100%', sm: '100%', md: '100%' }, p: '10px', m: 'auto' }} />

                    <Divider />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px'
                        }}
                    >
                        {headerMenu.map((item, index) => (

                            <NavbarMenuLinkMobile
                                key={item?.id}
                                item={item}
                                onClick={handleLinkClick}
                            />
                        ))}
                    </Box>

                    <Divider />

                    <SocialsList size={30} containerStyle={{ justifyContent: 'center', mt: '20px' }} />


                </Box>
            </Drawer>

        </Box>

    )
}


export default memo(Header)