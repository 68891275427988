import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

import { AppLayout } from 'layouts'
import { useParams } from 'react-router-dom'

const personalDetails = [
    {
        text1: 'Call',
        text2: 'H2020-MSCA-ITN-2020',
    },
    {
        text1: 'Type of Action',
        text2: 'MSCA-ITN-ETN',
    },
    {
        text1: 'Acronym',
        text2: 'META WIRELESS',
    },
    {
        text1: 'GA Number',
        text2: '956256',
    },
    {
        text1: 'Coordinator',
        text2: 'CNIT (Consorzio Nazionale Interuniversitario per le Telecomunicazioni)',
    },
    {
        text1: 'Coordinator person',
        text2: 'Dr. Stefano Buzzi',
    },

]

const PersonDetail = () => {

    const { name } = useParams()



    return (
        <AppLayout>
            <Typography
                sx={{
                    fontFamily: 'Poppins-Semibold',
                    fontSize: '20px',
                    lineHeight: '24px',
                    color: '#222',
                    mb: '20px',
                    textTransform: 'uppercase',
                    textAlign: { xs: 'center', md: 'left' }
                }}
            >
                Dr. {name}
            </Typography>

            <Grid container spacing={3} sx={{ mb: '40px' }}>
                <Grid item xs={12} md={6} lg={4} >
                    <Box sx={{ maxWidth: '400px', m: { xs: 'auto', md: '0' } }}>
                        <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Unknown_person.jpg/925px-Unknown_person.jpg' alt="" style={{ width: '100%', height: '100%' }} />
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} lg={8} >
                    <ul style={{ marginLeft: '20px' }}>
                        {
                            personalDetails?.map((item, index) => {


                                return (
                                    <li
                                        key={index}
                                        style={{
                                            fontFamily: 'Poppins-Regular',
                                            fontSize: '16px',
                                            lineHeight: '22px',
                                            color: '#666',
                                            textAlign: 'justify',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontFamily: 'Poppins-Medium',
                                                // fontSize: '16px',
                                                // lineHeight: '22px',
                                                color: '#000',
                                            }}
                                        >
                                            {item?.text1} :
                                        </span> &nbsp;
                                        {item?.text2}
                                    </li>
                                )
                            })
                        }

                    </ul>
                </Grid>
            </Grid>

            <Typography
                sx={{
                    fontFamily: 'Poppins-Medium',
                    fontSize: '18px',
                    lineHeight: '24px',
                    color: '#222',
                    mb: '20px',
                    textAlign: { xs: 'center', md: 'left' }
                }}
            >
                Short Biography
            </Typography>

            <Typography
                sx={{
                    fontFamily: 'Poppins-Regular',
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: '#222',
                    mb: '20px',
                    textAlign: { xs: 'center', md: 'left' }
                }}
            >
                Javad Shabanpour was born in Babol, 1994. He received his B.Sc. and M. Sc. degrees from Babol Noshirvani University of Technology and Iran university of science and technology, in 2016 and 2019 respectively.  Now, he is pursuing the Ph.D. degree on IRS for future 6G communications. He is working as a researcher within the framework of “Theoretical and Applied Electromagnetics of Complex Media” Labrotary supervised by Profs. Sergei Tretyakov and Constantin Simovski belongs to the Department of Electronics and Nanoengineering, Aalto University, Finland.
                <br />
                <br />
                His current research interests are in the areas of applied electromagnetics, metamaterials, plasmonics, and nanophotonics, with applications ranging from microwaves to optical frequencies. He is an Early-Stage Researcher that worked on “Multi-function reconfigurable intelligent surfaces (RISs) structures for wireless networks” funded by the Meta Wireless project within the framework of the H2020 Marie Skłodowska-Curie Innovative Training Networks (ITNs).            </Typography>

        </AppLayout>
    )
}
export default PersonDetail