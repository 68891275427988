import React from 'react';
import ReactDOM from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';

import App from './App';
import './sass/main.scss'
import './index.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import 'react-photo-view/dist/react-photo-view.css';
import DataContextProvider from 'data';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CookiesProvider>
    <DataContextProvider>
      <App />
    </DataContextProvider>
  </CookiesProvider>
);
