import React from 'react'
import { Box, Typography, Link } from '@mui/material'
import { useDataContext } from 'data'
import { Link as RouterLink } from 'react-router-dom'


const widgedOneList = [
    {
        text1: 'Issue #05 (March 2022) of MetaWireless',
        text2: 'Project Newsletter',
    },
    {
        text1: 'The first STEM Activity of the',
        text2: 'METAWIRELESS project',
    },
    {
        text1: 'DEM-1 Job Position Has Been',
        text2: 'Reopened!',
    },
    {
        text1: 'Issue #04 (December 2022) of',
        text2: 'MetaWireless Project Newsletter',
    },
    {
        text1: 'ESR Award assigned to Javad',
        text2: 'Shabanpour',
    },
    {
        text1: 'MetaWireless Plenery Meeting was held',
        text2: 'on Monday 14/11/22 in Gaeta',
    },


]

const WidgedTwo = () => {
    const { news } = useDataContext()
    return (
        <Box
            sx={{
                mb: '50px',

                '& ul li p:hover': {
                    color: '#000 !important',
                    fontFamily: 'Poppins-Medium !important',
                    // backgroundColor: '#999'
                }
            }}
        >
            <Typography
                sx={{
                    fontFamily: 'Poppins-Semibold',
                    fontSize: '18px',
                    lineHeight: '22px',
                    color: '#222',
                    pb: '10px',
                    mb: '10px',
                    borderBottom: '1px solid #ccc'
                }}
            >
                Last News
            </Typography>

            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >

                {
                    news?.slice(0, 8)?.map((item, index) => {


                        return (
                            <Link
                                to={`/news-detail/${item?.title?.toLowerCase()?.split(' ').join('-')}`}
                                component={RouterLink}
                                key={index}
                                sx={{
                                    fontFamily: 'Poppins-Regular',
                                    fontSize: '14px',
                                    lineHeight: '28px',
                                    color: '#666',
                                    borderBottom: '1px solid #ccc',
                                    textDecoration: 'none',
                                    py: '5px',

                                    '&:hover': {
                                        color: '#000',
                                    }
                                }}
                            >
                                {item?.title}
                            </Link>
                        )
                    })
                }

            </Box>


        </Box>
    )

}

export default WidgedTwo