import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import {
    Home,

    Objectives,
    Workpackages,

    Candidates,
    PersonDetail,
    Partners,
    ManagementTeam,

    Training,
    News,
    NewsDetail,
    Dissemination,
    Contact,
    NotFound,

    Cookies,
} from 'pages'
import { routerLinks } from './routerSettings'
import { CookiesOne } from 'components';


const AppRouter = () => {
    return (
        <BrowserRouter>

            <Routes>
                <Route path='' element={<Home />} />
                <Route path={routerLinks?.home} element={<Home />} />

                <Route path={routerLinks?.objectives} element={<Objectives />} />
                <Route path={routerLinks?.workpackages} element={<Workpackages />} />

                <Route path={routerLinks?.candidates} element={<Candidates />} />
                <Route path={routerLinks?.personDetail} element={<PersonDetail />} />
                <Route path={routerLinks?.partners} element={<Partners />} />
                <Route path={routerLinks?.managementTeam} element={<ManagementTeam />} />

                <Route path={routerLinks?.training} element={<Training />} />
                <Route path={routerLinks?.news} element={<News />} />
                <Route path={routerLinks?.newsDetail} element={<NewsDetail />} />
                <Route path={routerLinks?.dissemination} element={<Dissemination />} />
                <Route path={routerLinks?.contact} element={<Contact />} />

                <Route path={routerLinks?.cookies} element={<Cookies />} />

                <Route path='*' element={<NotFound />} />
            </Routes>

            <CookiesOne />
        </BrowserRouter>
    )
}

export default AppRouter