import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Container, Typography, Link } from '@mui/material';


const CookiesOne = () => {

    const [drawer, setDrawer] = useState(true)
    const [cookies, setCookie] = useCookies();

    // console.log({ cookies })

    const handleCookieAccept = () => {
        setDrawer(false)
        setCookie("corede_cookie", true)
    }

    const handleCookieReject = () => {
        setDrawer(false)
        // setCookie("corede_cookie", false)
    }

    return (
        <Box>

            {
                ((cookies?.corede_cookie !== "true") && drawer) && (
                    <Box
                        sx={{
                            paddingTop: '50px',
                            paddingBottom: '50px',
                            backgroundColor: 'rgba(255, 255, 255, 0.486)',
                            boxShadow: '1px 1px 20px 0px rgba(221, 221, 221, 0.4)',
                            backdropFilter: 'blur(8px)',
                            zIndex: 1000,

                            position: "fixed",
                            bottom: 0,
                            left: 0,
                            width: '100%',
                        }}
                    >
                        <Container maxWidth="xl">
                            <Box
                                className="container"
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexWrap: 'wrap', gap: '20px',
                                    // backgroundColor: 'red',
                                    // width: '150%',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            // ...theme.typography.subtitle1,
                                            // color: theme.palette.text.secondary,
                                            // fontWeight: '200'
                                            fontFamily: 'Poppins-Medium',
                                            fontWeight: '500'

                                        }}
                                    >
                                        {/* {translate("cookies.description")} */}
                                        {/* Cookies policies */}
                                        We collect cookies to analyze our website traffic and performance; we never collect any personal data:
                                    </Typography>

                                    <Link
                                        to="cookies"
                                        component={RouterLink}

                                        sx={{
                                            color: '#000',
                                            fontFamily: 'Poppins-Medium',

                                        }}
                                    >
                                        Cookies Policy
                                    </Link>
                                </Box>

                                <Box sx={{ display: 'flex', gap: '10px', width: { xs: '100%', md: 'auto' }, justifyContent: 'center' }}>

                                    {/* <Button
                                        onClick={handleCookieReject}
                                        sx={{
                                            borderRadius: "8px",
                                            backgroundColor: 'rgba(28, 28, 28, 0.5)',
                                            fontFamily: 'Poppins-Semibold',
                                            fontWeight: '500',
                                            color: '#fff',
                                            px: '20px',

                                            '&:hover': {
                                                backgroundColor: 'rgba(28, 28, 28, 0.5)',
                                                transform: 'scale(1.01)',
                                            }
                                        }}
                                    >
                                        reject
                                    </Button> */}

                                    <Button
                                        onClick={handleCookieAccept}
                                        sx={{
                                            borderRadius: "8px",
                                            backgroundColor: '#3139A9',

                                            // backgroundColor: 'rgba(28, 28, 28, 0.5)',
                                            fontFamily: 'Poppins-Semibold',
                                            fontWeight: '500',
                                            color: '#fff',
                                            px: '20px',
                                            '&:hover': {
                                                backgroundColor: '#3139A9',

                                                transform: 'scale(1.01)',
                                            }
                                        }}
                                    >
                                        {/* {translate("cookies.acceptButton")} */}
                                        {/* accept */}
                                        OK
                                    </Button>

                                </Box>

                            </Box>
                        </Container>
                    </Box>
                )
            }

        </Box>
    )
}

export default CookiesOne