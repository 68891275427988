import { Box } from '@mui/material'
import { NotFoundImage } from 'assets'
import { AppLayout } from 'layouts'
import React from 'react'

const NotFound = () => {
    return (
        <AppLayout homeLayout>

            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    positions: 'relative',
                }}
            >

                <Box>
                    <img src={NotFoundImage} alt="" style={{ width: '100%', height: '100%' }} />
                </Box>

            </Box>

        </AppLayout>
    )
}

export default NotFound